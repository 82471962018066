<!-- Search Contents with file uploader -->
<div class="w-full" *transloco="let t; read: 'askMyBrainSearch'">
  <!-- search Row -->
  <div class="flex flex-col md:flex-row gap-x-2 items-center max-w-full">
    <!-- search field  -->
    <div
      class="search-default border-[1px] border-solid"
      [ngStyle]="{ 'border-color': customizeColors ? customizeColorValues?.PrimaryColor : '#8E78B5' }"
    >
      <!-- My Brain logo label  -->
      <div class="absolute top-0 left-0 px-1 bg-white translate-x-5 -translate-y-2 rounded-lg">
        <img src="./assets/images/icons/search-bar/search-bar-icon.svg" class="w-[65px] h-[15px]" />
      </div>
      <div class="flex flex-col gap-4 items-center flex-1 py-2 lg:py-4 lg:flex-row lg:gap-0">
        <div class="flex-1 flex flex-row input-space-0 w-full">
          <ng-container *ngIf="allowModuleSelect; else chatIcon">
            <div class="flex self-start mr-2 mt-1">
              <div class="flex flex-col">
                <label for="selectedModule" class="text-xs mb-1 px-2">
                  <img src="./assets/images/icons/search-bar/lightbulb-blue-icon.svg" class="inline-block" />
                  {{ t("selectModule") }}
                </label>
                <select
                  class="text-xs bg-[#8e78b533] rounded-md px-2 py-1 mr-1 max-w-[150px] truncate"
                  (change)="onModuleChange($event)"
                  [ngModel]="(AppStore.selectedModule$ | async) || ''"
                >
                  <option value="none">{{ t("allModules") }}</option>
                  <option *ngFor="let module of validModules" [value]="module[0]">
                    {{ module[0] }} - {{ module[1] }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="allowBrainLLMSelect">
            <div class="flex self-start mr-2 mt-1">
              <!-- BrainLLM Selection Dropdown -->
              <div class="flex flex-col">
                <label for="selectedBrainLLM" class="text-xs mb-1 px-2">
                  <img src="./assets/images/icons/search-bar/chat-icon.svg" class="inline-block" />
                  BRAiN LLM
                </label>
                <select
                  class="text-xs bg-[#8e78b533] rounded-md px-2 py-1 mr-1"
                  (change)="onBrainLLMChange($event)"
                  [(ngModel)]="selectedBrainLLM"
                >
                  <option *ngFor="let llm of validBrainLLMs" [value]="llm">
                    {{ llm.split("/")[1] }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-template #chatIcon>
            <!-- Chat Icon  -->
            <div class="max-w-[18px] flex self-start mr-2 mt-1">
              <img src="./assets/images/icons/search-bar/chat-icon.svg" />
            </div>
          </ng-template>
          <!-- Input Form Field -->
          <textarea
            id="message"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="4"
            class="flex flex-1 text-zinc-900 text-base font-normal font-['Noto Sans'] leading-normal tracking-tight focus:outline-none lg:overflow-hidden resize-none"
            [formControl]="search"
            [placeholder]="searchPlaceholder"
            (keydown)="onKeyPress($event)"
          ></textarea>
        </div>

        <!-- Search Right Action Buttons  -->
        <div class="flex self-end items-center lg:self-center ml-2">
          <button
            mat-fab
            color="primary"
            class="!w-[52px] !h-[52px] md:!w-[42px] md:!h-[42px] record-btn p-3 rounded-lg violet-semi-dark md:mr-0 mr-2"
            *ngIf="showCameraIcon"
            (click)="openCamera()"
          >
            <img class="w-[32px] md:w-[22px]" src="./assets/images/icons/search-bar/camera_enhance_24px.svg" />
          </button>
          <ng-template #cameraIcon>
            <button
              mat-fab
              color="primary"
              class="!w-[52px] !h-[52px] md:!w-[42px] md:!h-[42px] record-btn p-3 rounded-lg violet-semi-dark md:mr-0 mr-2"
              *ngIf="showCameraIcon"
              (click)="openCamera()"
            >
              <div class="bg-blue-200 rounded-full p-1 w-full h-full">
                <div class="bg-blue-300 text-center rounded-full p-1 w-full h-full flex">
                  <img class="w-[32px] md:w-[22px]" src="./assets/images/icons/search-bar/camera_enhance_24px.svg" />
                </div>
              </div>
            </button>
          </ng-template>
          <!-- record mic button  -->
          <button
            mat-fab
            color="primary"
            class="!w-[52px] !h-[52px] md:!w-[42px] md:!h-[42px] record-btn p-3 rounded-lg violet-semi-dark mr-5 md:mr-1"
            *ngIf="!isUserSpeaking; else speakingIcon"
            (click)="switchRecording()"
          >
            <img
              *ngIf="!customizeColors"
              class="w-[32px] md:w-[22px]"
              src="./assets/images/icons/search-bar/mic-violet.svg"
            />

            <svg
              *ngIf="customizeColors"
              width="18"
              height="19"
              class="w-[32px] md:w-[22px] mr-5 md:mr-0"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              [style.fill]="customizeColors ? customizeColorValues?.PrimaryColor : '#8E78B5'"
            >
              <g id="mic_24px">
                <path
                  id="icon/av/mic_24px"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.25 9.23621C11.25 10.4812 10.245 11.4862 9 11.4862C7.755 11.4862 6.75 10.4812 6.75 9.23621V4.73621C6.75 3.49121 7.755 2.48621 9 2.48621C10.245 2.48621 11.25 3.49121 11.25 4.73621V9.23621ZM9 12.9862C11.07 12.9862 12.75 11.3062 12.75 9.23621H14.25C14.25 11.8837 12.2925 14.0587 9.75 14.4262V16.7362H8.25V14.4262C5.7075 14.0587 3.75 11.8837 3.75 9.23621H5.25C5.25 11.3062 6.93 12.9862 9 12.9862Z"
                />
              </g>
            </svg>
          </button>

          <ng-template #speakingIcon>
            <button
              mat-fab
              color="primary"
              class="!w-[52px] !h-[52px] md:!w-[42px] md:!h-[42px] record-btn p-3 rounded-lg violet-semi-dark mr-5 md:mr-1"
              (click)="switchRecording()"
            >
              <div class="bg-blue-200 rounded-full p-1">
                <div class="bg-blue-300 text-center rounded-full p-1">
                  <img
                    class="w-[32px] md:w-[22px]"
                    src="./assets/images/icons/search-bar/mic-violet.svg"
                    [style.fill]="customizeColors ? customizeColorValues?.PrimaryColor + ' !important' : '#8E78B5'"
                  />
                </div>
              </div>
            </button>
          </ng-template>

          <!-- File upload button modal trigger -->
          <button
            *ngIf="userDataLimits && canAddContent"
            brainLimitIndicator
            [limitConfig]="{
              isContentExceeded: userDataLimits.isContentExceeded
            }"
            [disabled]="userDataLimits.isContentExceeded"
            mat-stroked-button
            class="w-auto h-[42px] !min-w-[40px] file-upload-btn flex-1"
            (click)="openDialog()"
            [ngStyle]="{
              'background-color': customizeColors ? addAlpha(customizeColorValues?.PrimaryColor, 0.2) : '#f1eef6'
            }"
          >
            <img src="./assets/images/icons/search-bar/attach-file-violet.svg" />
            <span class="text-violet-semi">{{ t("addFileURL") }}</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Submit Button for disable case add class "opacity-50" -->
    <div class="hidden md:flex" *ngIf="userDataLimits">
      <button
        brainLimitIndicator
        [limitConfig]="{
          isContentExceeded: userDataLimits.isQuestionExceeded
        }"
        mat-fab
        class="hidden w-[34px] icon-btn h-[34px] px-2 py-3 rounded-lg"
        [style.background-color]="customizeColors ? customizeColorValues?.PrimaryColor + ' !important' : '#8E78B5'"
        [class.scale-x-[-1]]="activeLang === 'ar_ar'"
        (click)="sendClick()"
        [disabled]="
          (AppStore.chatApiInProgress$ | async)?.inProgress ||
          addBrainContentInProgress ||
          !search.value.length ||
          userDataLimits.isQuestionExceeded
        "
      >
        <img src="./assets/images/icons/search-bar/search-submit-btn.svg" />
      </button>
      <button
        brainLimitIndicator
        [limitConfig]="{
          isContentExceeded: userDataLimits.isQuestionExceeded
        }"
        class="advanced-search-btn w-[42px] icon-btn h-[42px] px-2 py-3 rounded-lg flex items-center gap-2 hover:w-auto transition-all duration-300 cursor-pointer"
        [class.scale-x-[-1]]="activeLang === 'ar_ar'"
        (click)="sendClick(false, true)"
        [disabled]="
          (AppStore.chatApiInProgress$ | async)?.inProgress ||
          addBrainContentInProgress ||
          !search.value.length ||
          userDataLimits.isQuestionExceeded
        "
      >
        <img
          src="./assets/images/icons/search-bar/deep-search.svg"
          class="relative bottom-1 max-h-[32px] max-w-[32px] pt-1"
        />
        <span
          class="advanced-search-text text-xs leading-tight opacity-0 max-w-0 overflow-hidden transition-all duration-300 w-[90px] text-violet-semi"
        >
          {{ t("deepSearch") }}
        </span>
      </button>
    </div>

    <div class="md:hidden w-full pt-4" *ngIf="userDataLimits">
      <button
        class="w-full h-[38px] flex justify-center items-center rounded-lg gap-1"
        brainLimitIndicator
        [limitConfig]="{
          isContentExceeded: userDataLimits.isQuestionExceeded
        }"
        [style.background-color]="customizeColors ? customizeColorValues?.PrimaryColor + ' !important' : '#8E78B5'"
        (click)="sendClick()"
        [disabled]="
          (AppStore.chatApiInProgress$ | async)?.inProgress ||
          addBrainContentInProgress ||
          !search.value.length ||
          userDataLimits.isQuestionExceeded
        "
      >
        <span class="text-right text-white text-[15px]">{{ t("send") }}</span>
        <img src="./assets/images/icons/search-bar/search-submit-btn.svg" />
      </button>
    </div>
  </div>
</div>
