/* eslint-disable max-lines */
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { ConversationService } from "app/services/conversation.service";
import { UsersService } from "app/services/users.service";
import { MarkdownService } from "ngx-markdown";
import { Observable, Subject, Subscription, catchError, map, mergeMap, of, switchMap, throwError } from "rxjs";
import { Conversation, QueryResponseMetadata } from "./api-models/conversation.models";
import { AppStore } from "./app-store.service";
import { InternetSearchStatus, ProgressData } from "./app.model";
import { LS_TOKEN, LS_USER_GEO } from "./const/app-constant";
import { HttpClientService } from "./core/modules/http-client/http-client.service";
import { environment } from "./environments/environment";
import { BrainLLM, ChatDataRole, GetAssistantResponse, defaultBrainLLM } from "./pages/my-brain/my-brain.model";

@Injectable({
  providedIn: "root",
})
export class AppService {
  allowBrainLLMSelect = environment.featureFlags.allowBrainLLMSelect;
  allowModuleSelect = environment.featureFlags.allowModuleSelect;

  constructor(
    private http: HttpClientService,
    private conversationService: ConversationService,
    private translocoService: TranslocoService,
    private markdownService: MarkdownService,
    private userService: UsersService,
  ) {}

  public dashboardSubject: Subject<boolean> = new Subject<boolean>();

  formatResponse(responseText: string) {
    return this.markdownService.parse(responseText);
  }

  askQueryFromSharedBrainContent(
    query: string,
    embeddedChatCreator: string,
    conversation: Conversation,
    deepThinkEnabled = false,
  ) {
    const url = `${environment.dotNetBaseUrl}/api/embeddings/shared/query`;

    conversation.hidden = true;
    const requestData = {
      user: embeddedChatCreator,
      userQuery: conversation,
    };

    this.askQuery(query, requestData, url, deepThinkEnabled, true);
  }
  askQueryFromBrainContent(query: string, conversation: Conversation, deepThinkEnabled: boolean) {
    const url = `${environment.dotNetBaseUrl}/api/embeddings/query`;

    conversation.hidden = false;
    this.askQuery(query, { userQuery: conversation }, url, deepThinkEnabled, false);
  }

  askQuery(query: string, requestData: any, url: string, deepThinkEnabled: boolean, isEmbeddedChat: boolean) {
    const chats = AppStore.allChats$.value;
    const brain = AppStore.selectedBrain$.value;
    const user = AppStore.userData$.value;
    const currentBrainLLM = this.allowBrainLLMSelect ? AppStore.selectedBrainLLM$.value : defaultBrainLLM;
    const filter =
      this.allowModuleSelect && AppStore.selectedModule$.value !== "none"
        ? { module: AppStore.selectedModule$.value }
        : null;

    const requestObservable = this.getGeoLocation().pipe(
      mergeMap((res) => {
        const { latitude, longitude, address } = res;
        requestData = {
          ...requestData,
          query: query,
          assistantId: user?.openAIAssistantId,
          interactiveInternetSearch: brain?.interactiveInternetSearch,
          messages: chats.map(({ role, text, metadata }) => ({ role, text, metadata })),
          pineconeIndexName: environment.pineconeIndexName,
          namespace: brain?.id,
          deepThinkEnabled: deepThinkEnabled,
          ...(res.latitude !== null && { latitude }),
          ...(longitude !== null && { longitude }),
          ...(address !== null && { address }),
          ...(this.allowBrainLLMSelect && { currentBrainLLM }),
          ...(filter && { filter }),
        };

        return this.http.post<Conversation>(url, requestData, { isAuth: !isEmbeddedChat });
      }),
    );

    const startTime = new Date().getTime();
    let endTime: number | undefined;

    requestObservable
      .pipe(
        mergeMap((resp) => {
          if (resp && resp.isSuccess && resp.data && !AppStore.clearChat$.value) {
            let metadata = resp?.data?.metadata || {};

            this.cacheGeoLocation(metadata);

            ({ metadata, endTime } = this.appendRequestLogs(metadata, startTime, endTime));

            if (isEmbeddedChat) return of({ resp: resp, userResponse: null });

            const getUserObservable = this.userService.get();
            return getUserObservable.pipe(map((userResponse) => ({ resp, userResponse })));
          }

          return of({ resp: null, userResponse: null });
        }),
        catchError((error) => {
          const errorMessage = this.translocoService.translate("queryIssueMsg");
          const conversation: Conversation = {
            project: isEmbeddedChat ? "" : brain?.id,
            references: [] as string[],
            role: ChatDataRole[ChatDataRole.assistant],
            text: errorMessage,
            imageUrls: [] as string[],
          } as Conversation;

          const createErrorMessage$ = this.conversationService.create(conversation);

          return createErrorMessage$.pipe(
            switchMap((res) => {
              AppStore.allChats$.next([
                ...AppStore.allChats$.value.filter((c) => c.text),
                {
                  ...conversation,
                  id: res.data?.id,
                },
              ]);
              AppStore.chatApiInProgress$.next({ brainId: res.data?.id || "", inProgress: false });
              return throwError(error);
            }),
          );
        }),
      )
      .subscribe(({ resp, userResponse }) => {
        if (resp === null && userResponse === null) {
          return;
        }

        if (userResponse && userResponse.isSuccess && userResponse.data) {
          AppStore.userData$.next(userResponse.data);
        }

        const chatId = resp?.data?.id as string;
        let metadata = resp?.data?.metadata || {};
        this.cacheGeoLocation(metadata);
        if (resp?.data?.text || (resp?.isSuccess && resp?.data)) {
          const formattedResponse = this.formatResponse(resp.data.text || resp.data.text);
          let answer = resp.data.text || resp.data.text;
          const assistantResponse = GetAssistantResponse(answer);
          if (assistantResponse) {
            answer = this.translocoService.translate(assistantResponse.translationKey);
          }

          ({ metadata, endTime } = this.appendRequestLogs(metadata, startTime, endTime));

          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: answer,
              creationDate: new Date(),
              detailedReferences: resp.data.detailedReferences,
              references: resp.data?.references,
              formattedContent: formattedResponse,
              isAssistantQuestion: !!assistantResponse,
              id: chatId,
              project: brain?.id,
              imageUrls: resp?.data?.imageUrls || [],
              metadata: JSON.stringify(metadata),
            } as Conversation,
          ]);
        } else {
          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: this.translocoService.translate("queryIssueMsg"),
              creationDate: new Date(),
              formattedContent: this.translocoService.translate("queryIssueMsg"),
              id: resp.data?.id,
              project: brain?.id,
            } as Conversation,
          ]);
        }

        if (brain) {
          AppStore.chatApiInProgress$.next({ brainId: brain?.id, inProgress: false });
        }
      });
  }

  getISQuery(isEmbeddedChat: boolean) {
    const url = localStorage.getItem("internet-search-query-url");
    return url
      ? `${environment.dotNetBaseUrl}${url}`
      : isEmbeddedChat
      ? `${environment.dotNetBaseUrl}/api/embeddings/shared/ask-internet-question`
      : `${environment.dotNetBaseUrl}/api/embeddings/ask-internet-question`;
  }

  async uploadZipFileWithProgress(formData: FormData) {
    const token = localStorage.getItem(LS_TOKEN);

    const response = await fetch(`${environment.dotNetBaseUrl}/api/EmbeddingFile/upload-zip`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "text/event-stream",
      },
      keepalive: true,
      signal: AbortSignal.timeout(60 * 60 * 1000), // 60 minutes
    });
    const reader = response?.body?.getReader();
    const answer = "";
    if (reader) {
      const decoder = new TextDecoder();
      let buffer = "";
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        let separator;

        while ((separator = buffer.indexOf("\n\n")) !== -1) {
          const message = buffer.slice(0, separator);
          buffer = buffer.slice(separator + 2);

          if (message.startsWith("data: ")) {
            const jsonString = message.replace("data: ", "");
            const parsedData: ProgressData = JSON.parse(jsonString);
            const fileName = (formData.get("zipFile") as File)?.name;
            const currentProgress = AppStore.zipUploadProgress$.getValue() || [];
            const existingFileIndex = currentProgress.findIndex((p) => p.FileName === fileName);
            parsedData.FileName = fileName;

            if (existingFileIndex !== -1) {
              // Update existing entry
              const updatedProgress = [...currentProgress];
              updatedProgress[existingFileIndex] = parsedData;
              AppStore.zipUploadProgress$.next(updatedProgress);
            } else {
              // Add new entry
              AppStore.zipUploadProgress$.next([...currentProgress, parsedData]);
            }
          }
          console.log(answer);
        }
      }
    }
  }

  async askQueryFromChatGPTStream(
    query: string,
    brainId: string,
    prevChats: Conversation[],
    isEmbeddedChat: boolean,
    brainLLM: BrainLLM,
    embeddedChatCreator: string,
    userQueryConversation: Conversation | null,
    filter?: { [key: string]: string } | null,
  ): Promise<Conversation> {
    AppStore.cancelAnswerStream$.next(true); // Log cancelation
    AppStore.answer$.next("");
    AppStore.cancelAnswerStream$.next(false); // Log reset

    let stopStreamSubscription: Subscription | null = null;
    try {
      let latitude: number | null = null;
      let longitude: number | null = null;
      let address: string | null = "";

      this.getGeoLocation().subscribe((res) => {
        latitude = res.latitude;
        longitude = res.longitude;
        address = res.address;
      });

      const token = localStorage.getItem(LS_TOKEN);
      const startTime = new Date().getTime();
      let endTime: number | undefined;

      const response = await fetch(this.getISQuery(isEmbeddedChat), {
        method: "POST",
        body: JSON.stringify({
          openAIKey: "none",
          query: query,
          brainId,
          messages: prevChats.map(({ role, text, metadata }) => {
            return { role, text, metadata } as Partial<Conversation>;
          }),
          ...(latitude !== null && { latitude }),
          ...(longitude !== null && { longitude }),
          ...(address !== null && { address }),
          ...(this.allowBrainLLMSelect && { brainLLM }),
          ...(filter && { filter }),
          user: isEmbeddedChat ? embeddedChatCreator : "",
          userQuery: isEmbeddedChat ? null : userQueryConversation,
        }),
        headers: {
          "Content-Type": "application/json", // ensure JSON content type is set
          Authorization: `Bearer ${token}`,
          Accept: "text/event-stream", // replace YOUR_TOKEN_HERE with the actual token
        },
      });

      const reader = response?.body?.getReader();
      let answer = "";
      let references = [];
      let metadata = {};
      let canceled = false;
      if (reader) {
        const decoder = new TextDecoder();
        let buffer = "";
        stopStreamSubscription = AppStore.clearChat$.subscribe((clear) => {
          if (!clear) return;

          reader.cancel();
          canceled = true;
          AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
        });

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          let separator;

          while ((separator = buffer.indexOf("\n\n")) !== -1) {
            const message = buffer.slice(0, separator);
            buffer = buffer.slice(separator + 2);

            if (message.startsWith("event: message")) {
              const encoded_content = message.split("\ndata: ")[1];
              const decodedUtf8Text = this.decodeEncodedUtf8Text(encoded_content);
              answer += decodedUtf8Text;
              this.processSseData(decodedUtf8Text);
            } else if (message.startsWith("event: metadata")) {
              const encoded_content = message.split("\ndata: ")[1];
              metadata = JSON.parse(atob(encoded_content)); // Base64 decoding
            } else if (message.startsWith("event: stop")) {
              const encoded_content = message.split("\ndata: ")[1];
              references = JSON.parse(atob(encoded_content));
              AppStore.internetSearchStatus$.next(InternetSearchStatus.searching);
            } else if (message.startsWith("event: status")) {
              const encoded_content = message.split("\ndata: ")[1];
              const decodedUtf8Text = this.decodeEncodedUtf8Text(encoded_content);
              AppStore.internetSearchStatus$.next(decodedUtf8Text as InternetSearchStatus);
            }
          }
        }
      }

      if (AppStore.clearChat$.value) return Promise.resolve({} as Conversation);

      this.cacheGeoLocation(metadata);

      ({ metadata, endTime } = this.appendRequestLogs(metadata, startTime, endTime));

      const conversation: Conversation = {
        role: ChatDataRole[ChatDataRole.assistant],
        text: answer as string,
        creationDate: new Date(),
        formattedContent: this.formatResponse(answer),
        project: isEmbeddedChat ? "" : brainId,
        references: references,
        metadata: JSON.stringify(metadata),
      } as Conversation;

      const createMessageObservable = this.conversationService.create(conversation);

      if (answer) {
        createMessageObservable.subscribe((resp) => {
          const updatedMessage = {
            ...conversation,
            id: resp.data?.id,
          } as Conversation;

          AppStore.allChats$.next([...AppStore.allChats$.value.filter((c) => c.text), updatedMessage]);
        });
      } else {
        AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
        createMessageObservable.subscribe((resp) => {
          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: this.translocoService.translate("queryIssueMsg"),
              creationDate: new Date(),
              formattedContent: this.translocoService.translate("queryIssueMsg"),
              id: resp.data?.id,
            } as Conversation,
          ]);
        });
      }
      return conversation;
    } catch (e) {
      const conversation = {
        role: ChatDataRole.assistant,
        text: this.translocoService.translate("queryIssueMsg"),
        creationDate: new Date(),
        formattedContent: this.translocoService.translate("queryIssueMsg"),
        project: brainId,
      } as Conversation;
      AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });

      AppStore.allChats$.next([...AppStore.allChats$.value.filter((c) => c.text), conversation]);
      return conversation;
    } finally {
      AppStore.answer$.next("");
      AppStore.answerStream$.next({ stop: true, char: "" });
      if (stopStreamSubscription) {
        stopStreamSubscription.unsubscribe();
      }
      AppStore.clearChat$.next(false);
    }
  }

  decodeEncodedUtf8Text(encoded_content: string): string {
    const binaryString = atob(encoded_content);
    const decodedUtf8Text = new TextDecoder("utf-8").decode(
      new Uint8Array([...binaryString].map((char) => char.charCodeAt(0))),
    );
    return decodedUtf8Text;
  }

  processSseData(data: string) {
    for (const char of data) {
      AppStore.answerStream$.next({ stop: false, char }); // Emit each character into the subject
    }
  }

  private parseReferences(references: any[] | undefined): string[] {
    if (!Array.isArray(references)) {
      return [];
    }

    return references
      .map((ref: any) => {
        if (typeof ref === "string") {
          return ref;
        } else if (typeof ref === "object") {
          return ref.title || ref.link;
        }
        return null;
      })
      .filter(Boolean);
  }

  getGeoLocation(): Observable<{ latitude: number | null; longitude: number | null; address: string | null }> {
    return new Observable((observer) => {
      if (!navigator.geolocation) {
        observer.error("Geolocation is not supported by this browser.");
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Fetch address from localStorage or any other source
          const userAddress = localStorage.getItem(`LS_USER_GEO,${latitude},${longitude}`);
          const address = userAddress ? userAddress : null;

          observer.next({ latitude, longitude, address });
          observer.complete();
        },
        (error) => {
          console.warn("User blocked location access:", error);
          observer.next({ latitude: null, longitude: null, address: null });
          observer.complete();
        },
      );
    });
  }

  private cacheGeoLocation(metadata: { geolocation?: string; address?: string }) {
    if (metadata.geolocation && metadata.address) {
      // metadata.geolocation is like f"{lat},{lon}"
      localStorage.setItem(`${LS_USER_GEO},${metadata.geolocation}`, metadata.address);
    }
  }

  private appendRequestLogs(
    metadata: any,
    startTime: number,
    endTime?: number,
  ): {
    metadata: QueryResponseMetadata;
    endTime: number | undefined;
  } {
    if (endTime === undefined) {
      endTime = new Date().getTime();
    }

    let queryResponseMetadata;
    if (typeof metadata === "string") {
      try {
        queryResponseMetadata = JSON.parse(metadata);
      } catch (error) {
        console.error("Invalid JSON string provided for metadata:", error);
        queryResponseMetadata = {}; // Fallback to an empty object if parsing fails
      }
    } else if (typeof metadata === "object" && metadata !== null) {
      queryResponseMetadata = metadata; // It's already an object
    } else {
      queryResponseMetadata = {}; // Handle cases where metadata is undefined, null, or other types
    }

    const duration = (endTime - startTime) / 1000;
    queryResponseMetadata.requestLogs = `DEV debug: ${
      queryResponseMetadata?.brainLLM || defaultBrainLLM
    } took ${duration}s`;
    return { metadata: queryResponseMetadata, endTime };
  }
}
